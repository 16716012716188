import { MedplumClient } from '@medplum/core'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import Cookies from 'js-cookie'

export const medplum = new MedplumClient({
    clientId: process.env.REACT_APP_MEDPLUM_CLIENT_ID,
    baseUrl: `${BASE_API_URL}/fhir-api`,
    fetch: async (url, options) => {
        return fetch(url, {
            ...options,
            headers: {
                ...((options && options.headers) || {}),
                'X-Xsrf-Token': Cookies.get('xsrf-token') || ''
            }
        })
    }
})
