import { useMutation, UseMutationOptions } from 'react-query'
import { MedplumImmunizationItem } from '../../../types'
import { useMedplumContext } from '@medplum/react'
import { MedplumClient } from '@medplum/core'

function editImmunization(medplum: MedplumClient) {
    return async (immunization: MedplumImmunizationItem) => {
        return await medplum.updateResource({
            ...immunization,
            resourceType: 'Immunization',
            patient: immunization.patient!
        })
    }
}

export const useEditImmunization = (
    options?: Omit<UseMutationOptions<any, any, any, any>, 'mutationFn'>
) => {
    const { medplum } = useMedplumContext()
    return useMutation(editImmunization(medplum), options)
}
