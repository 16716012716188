import { Card, Flex, Heading } from '@asktia/tia-ui'
import { FC, useMemo } from 'react'
import { ImmunizationField } from '../../components/ImmunizationField'
import { MedplumImmunizationItem } from '../../../../types'
import { FieldValues, UseFormMethods } from 'react-hook-form'
import { isNil } from 'lodash'
import { useAmpli } from 'src/hooks'

export const useRecommendedImmunizations = () =>
    useMemo(
        () => [
            {
                system: 'http://hl7.org/fhir/sid/cvx',
                code: '213',
                display: 'SARS-COV-2 (COVID-19) vaccine, UNSPECIFIED',

                form: {
                    label: 'Covid',
                    name: 'covid',
                    immunizationCode: '213',
                    supportText:
                        'The CDC and Tia recommend this for all adults without contraindications.'
                }
            },
            {
                system: 'http://hl7.org/fhir/sid/cvx',
                code: '88',
                display: 'influenza virus vaccine, unspecified formulation',

                form: {
                    label: 'Flu',
                    name: 'flu',
                    immunizationCode: '88',
                    supportText:
                        'The CDC and Tia recommend annual vaccination for all adults without contraindications.'
                }
            },
            {
                system: 'http://hl7.org/fhir/sid/cvx',
                code: '137',
                display: 'HPV, unspecified formulation',

                form: {
                    label: 'HPV',
                    name: 'hpv',
                    immunizationCode: '137',
                    supportText:
                        'The CDC and Tia recommend getting a full series (2 or 3 doses) from age 9-26. If you’re between 27-45, talk with your provider to see if this is right for you.'
                }
            },
            {
                system: 'http://hl7.org/fhir/sid/cvx',
                code: '112',
                display: 'tetanus toxoid, unspecified formulation',

                form: {
                    label: 'TDAP',
                    name: 'tdap',
                    immunizationCode: '112',
                    supportText:
                        'The CDC and Tia recommend getting a booster every 10 years, and once each pregnancy.'
                }
            },
            {
                system: 'http://hl7.org/fhir/sid/cvx',
                code: '188',
                display: 'zoster vaccine, unspecified formulation',

                form: {
                    label: 'Shingles',
                    name: 'shingles',
                    immunizationCode: '188',
                    supportText:
                        'The CDC and Tia recommend this for adults 50 years or older.'
                }
            },
            {
                system: 'http://hl7.org/fhir/sid/cvx',
                code: '109',
                display: 'pneumococcal vaccine, unspecified formulation',

                form: {
                    label: 'Pneumovax',
                    name: 'pneumovax',
                    immunizationCode: '109',
                    supportText:
                        'The CDC and Tia recommend this for adults 65 years or older or 19 years or older with certain conditions or risk factors.'
                }
            }
        ],
        []
    )

export const convertToApiFromForm = (
    values: any,
    recommendedImmunizations: {
        system: string
        code: string
        display: string
        form: {
            label: string
            name: string
            immunizationCode: string
            supportText: string
        }
    }[]
): MedplumImmunizationItem[] => {
    const result = recommendedImmunizations
        .map(immunization => {
            const hasImmunization = values[immunization.form.name]

            if (hasImmunization) {
                return {
                    resourceType: 'Immunization',
                    status: 'completed',
                    vaccineCode: {
                        coding: [
                            {
                                code: immunization.code,
                                display: immunization.display,
                                system: immunization.system
                            }
                        ],
                        text: immunization.display
                    },
                    // TODO: FIX LATER
                    occurrenceDateTime: new Date().toISOString()
                }
            }

            return undefined
        })
        .filter(x => !isNil(x)) as MedplumImmunizationItem[]

    return result
}

export const convertToFormPayload = (
    values: MedplumImmunizationItem[],
    recommendedImmunizations: {
        system: string
        code: string
        display: string
        form: {
            label: string
            name: string
            immunizationCode: string
            supportText: string
        }
    }[],
    formMethods: UseFormMethods<FieldValues>
) => {
    recommendedImmunizations.forEach(immunization => {
        const hasCurrentImmunization = values
            .filter(immunization => immunization.status === 'completed')
            .some(
                x =>
                    x.vaccineCode.coding[0].code.toString() ==
                    immunization.code.toString()
            )

        formMethods.setValue(immunization.form.name, hasCurrentImmunization)
    })
}

export const RecommendedImmunizations: FC = () => {
    const recommendedImmunizations = useRecommendedImmunizations()
    const { clickedOnRecommendedImmunization } = useAmpli()

    return (
        <>
            <Heading h2>Recommended Immunizations</Heading>
            <Card variant="info" sx={{ mb: 4 }}>
                If you don’t have your vaccine record on hand, it’s common to be
                able to download it from your city or state’s health department
                website. An estimate of the date is also acceptable if you don’t
                remember or cannot find it.
            </Card>

            <Flex sx={{ flexDirection: 'column' }}>
                {recommendedImmunizations.map(immunization => (
                    <ImmunizationField
                        {...immunization.form}
                        immunizationCode={immunization.code}
                        immunizationDisplay={immunization.display}
                        immunizationSystem={immunization.system}
                        isRecommended
                        onCheckboxClick={evt =>
                            clickedOnRecommendedImmunization({
                                Checked: (evt.target as any).checked
                            })
                        }
                    />
                ))}
            </Flex>
        </>
    )
}
