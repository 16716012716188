import { Immunization } from '@medplum/fhirtypes'
import { isNil } from 'lodash'

export const lastTouchedByPatient = (immunization: Immunization) => {
    const notYetFetched = isNil(immunization?.meta?.author?.display)
    const lastEditedByPatient = immunization?.meta?.author?.display
        ?.toLowerCase()
        ?.includes('www')
    return notYetFetched || lastEditedByPatient
}
